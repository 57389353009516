import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
import encHex from 'crypto-js/enc-hex'
import { generateSiweNonce } from 'viem/siwe'

export type KLineUnit = '1d' | '12h' | '8h' | '4h' | '2h' | '1h' | '30m' | '15m' | '5m' | '3m' | '1m'

export const useWs = createGlobalState(() => {
  const kLineUnit = ref<KLineUnit>('15m')
  const assetBalance = ref<BalanceItem[]>([])
  const tradeList = ref<TradeItem[]>([])
  const kLineList = ref<KLineItem[]>([])
  const symbolPrice = ref<SymbolPrice>({})
  const orderbookList = ref<Orderbook>({
    asks: [],
    bids: [],
  })
  const subscribeList = ref<Record<string, SubscribeInfo>>({})
  const { apiKey } = useKey()
  const { currentSymbol, tickerInfo } = useSymbol()
  const notification = useNotification()
  const { preference, getUserAsset, userInfo } = useUser()
  const isNeedRefresh = ref(false)
  const isBalanceChange = ref(false)

  const { send: sendAssetBalance, open: subscribeAssetBalanceWs, status, close: closeAssetBalanceWs } = useWebSocket(
    `${location.protocol === 'http:' ? 'ws' : 'wss'}://${location.host}/ws/user`,
    {
      heartbeat: {
        message: 'ping',
        interval: 31 * 1000,
        pongTimeout: 100000,
      },
      immediate: false,
      autoReconnect: true,
      onMessage(ws, event) {
        if (event.data === 'ping') {
          sendAssetBalance('pong')
          sendAssetBalance('ping')
          return
        }
        if (event.data === 'pong') {
          return
        }
        try {
          const data = JSON.parse(event.data)
          if (data.id === 'authorize' && !data.error) {
            // sendAssetBalance(JSON.stringify(
            //   {
            //     type: 'subscribe',
            //     channel: 'mybalance',
            //     id: utils.randomHex(32),
            //   },
            // ))
            sendAssetBalance(JSON.stringify(
              {
                type: 'subscribe',
                channel: 'myaccount',
                id: generateSiweNonce().slice(0, 32),
              },
            ))
          }

          else if (data.channel === 'myaccount') {
            const assets = data.assets as BalanceItem[]
            if (assets) {
              for (const asset of assets) {
                const balance = assetBalance.value.find(i => i.assetName === asset.assetName)
                if (!balance) {
                  assetBalance.value = [...assetBalance.value, asset]
                }
                else {
                  assetBalance.value = assetBalance.value.map(i => i.assetName === asset.assetName ? asset : i)
                }
              }
            }
            // getUserAsset()
            if (data.type === 'update') {
              const deposits = data.deposits as DepositItem[]
              if (deposits) {
                for (const depositItem of deposits) {
                  notification.success({
                    title: 'Deposit Successful',
                    content: `Your deposit of ${depositItem.amount} ${depositItem.assetName} has been successfully processed.`,
                    duration: 2000,
                  })
                }
              }
            }
            if (['PLACE_ORDER', 'CANCEL_ORDER', 'ORDER_MATCH'].includes(data.event)) {
              isNeedRefresh.value = !isNeedRefresh.value
            }
            else {
              isBalanceChange.value = !isBalanceChange.value
            }
            if (data.event === 'ORDER_MATCH') {
              const match = data.orders as OrderItem[]
              if (match && preference.value.tradeNotification) {
                for (const item of match) {
                  notification.success({
                    title: 'Order Match',
                    content: `Your ${item.side === 'BUY' ? 'Buy' : 'Sell'} order for ${item.origQty} ${getCurrencyFromSymbol(item.symbol).baseAssetName} at ${item.price} has been matched. ${item.executedQty} ${getCurrencyFromSymbol(item.symbol).baseAssetName} has been executed.`,
                    duration: 2000,
                  })
                }
              }
            }
            if (data.event === 'PLACE_ORDER') {
              const match = data.orders as OrderItem[]
              if (match && preference.value.tradeNotification) {
                for (const item of match) {
                  if (item.status === 'EXPIRED')
                    notification.success({
                      title: 'Order Expired',
                      content: `Your ${item.side === 'BUY' ? 'Buy' : 'Sell'} order for ${item.origQty} ${getCurrencyFromSymbol(item.symbol).baseAssetName} at ${item.price} failed to execute.`,
                      duration: 2000,
                    })
                }
              }
            }
            if (data.event === 'UPDATE_USER_FEE_RATE') {
              userInfo.value.makerFeeRate = data.makerFeeRate
              userInfo.value.takerFeeRate = data.takerFeeRate
            }
          }
        }
        catch (e: any) {
          console.log(e)
        }
      },
      onConnected() {
        if (!apiKey.value) {
          return
        }
        const t = new Date().getTime()
        const id = 'authorize'
        const sig = Base64.stringify(hmacSHA256(
            `${t}websocket${id}`,
            encHex.parse(apiKey.value.secret.slice(2)),
        ))
        assetBalance.value = []
        sendAssetBalance(JSON.stringify({
          channel: 'authorize',
          type: 'subscribe',
          id,
          vesselApiKey: apiKey.value.key,
          vesselPassphrase: apiKey.value.passphrase,
          vesselSignature: sig,
          vesselTimestamp: t,
        }))
      },
      // onDisconnected(ws, event) {
      //   console.log(ws, event, 'closed11111111111111111111')
      // },
    },
  )

  const { send: sendWs } = useWebSocket(`${location.protocol === 'http:' ? 'ws' : 'wss'}://${location.host}/ws`, {
    heartbeat: false,
    autoReconnect: true,
    onMessage(ws, event) {
      if (event.data === 'ping') {
        sendWs('ping')
        sendWs('pong')
        return
      }
      if (event.data === 'pong') {
        return
      }
      const parseData = JSON.parse(event.data)
      if (parseData.channel === 'orderbook') {
        patchOrderbook(parseData.data)
      }
      else if (parseData.channel === 'trade') {
        patchTrade(parseData.data)
      }
      else if (parseData.channel === 'kline' && parseData.type === 'update' && parseData.interval === kLineUnit.value) {
        if (!Array.isArray(parseData.data)) {
          patchKLine([parseData.data])
        }
        else {
          patchKLine(parseData.data.slice(parseData.data.length - 50))
        }
      }
      else if (parseData.channel === 'symbol_stats') {
        symbolPrice.value = parseData.data
      }
      else if (parseData.channel === 'symbol_update') {
        if (parseData.type === 'update') {
          tickerInfo.value = tickerInfo.value.map(i => i.symbol !== parseData.symbolName
            ? i
            : ({
                ...i,
                takerFeeDiscount: parseData.takerFeeDiscount,
                makerFeeDiscount: parseData.makerFeeDiscount,
              }))
        }
      }
    },
    onConnected() {
      sendWs(JSON.stringify({
        type: 'subscribe',
        channel: 'symbol_stats',
        id: generateSiweNonce().slice(0, 32),
      }))
      sendWs(JSON.stringify({
        type: 'subscribe',
        channel: 'symbol_update',
        id: generateSiweNonce().slice(0, 32),
      }))
      if (currentSymbol.value) {
        subscribeSymbolChannel(currentSymbol.value, 'orderbook')
        subscribeSymbolChannel(currentSymbol.value, 'trade')
        // subscribeSymbolKLine(currentSymbol.value, kLineUnit.value)
      }
    },
  })

  function patchTrade(data: TradeItem[]) {
    const _tradeList = JSON.parse(JSON.stringify(tradeList.value)) as TradeItem[]
    for (const item of data) {
      if (!_tradeList.some(i => i.buyerOrderID === item.buyerOrderID && i.sellerOrderID === item.sellerOrderID)) {
        _tradeList.unshift(item)
      }
    }
    tradeList.value = _tradeList.sort((i, j) => +j.id - +i.id).slice(0, 50)
  }

  function patchOrderbook(data: Orderbook) {
    const orderbookCopy = JSON.parse(JSON.stringify(orderbookList.value)) as Orderbook
    for (const item of data.asks) {
      const findItem = orderbookCopy.asks.find(i => i.price === item.price)
      if (findItem) {
        findItem.orderCount = item.orderCount
        findItem.volume = item.volume
      }
      else {
        orderbookCopy.asks.push(item)
      }
    }
    for (const item of data.asks) {
      const findItem = orderbookCopy.asks.find(i => i.price === item.price)
      if (findItem) {
        findItem.orderCount = item.orderCount
        findItem.volume = item.volume
      }
      else {
        orderbookCopy.asks.push(item)
      }
    }
    for (const item of data.bids) {
      const findItem = orderbookCopy.bids.find(i => i.price === item.price)
      if (findItem) {
        findItem.orderCount = item.orderCount
        findItem.volume = item.volume
      }
      else {
        orderbookCopy.bids.push(item)
      }
    }
    orderbookCopy.asks = orderbookCopy.asks.filter(i => i.volume !== '0')
    orderbookCopy.bids = orderbookCopy.bids.filter(i => i.volume !== '0')
    orderbookList.value = orderbookCopy
  }
  function patchKLine(data: KLineItem[]) {
    const kLineCopy = JSON.parse(JSON.stringify(kLineList.value)) as KLineItem[]
    if (kLineCopy.length === 0 && data?.length === 1) {
      return
    }
    for (const item of data.filter(i => !!+i.openPrice)) {
      const findIndex = kLineCopy.findIndex(i => i.openTime === item.openTime)
      if (findIndex >= 0) {
        kLineCopy[findIndex] = item
      }
      else if (item.openTime) {
        kLineCopy.push(item)
      }
    }
    kLineList.value = kLineCopy.sort((i, j) => +j.closeTime - +i.closeTime)
  }

  function initList() {
    orderbookList.value = {
      asks: [],
      bids: [],
    }
    tradeList.value = []
  }

  function subscribeSymbolChannel(symbol: string, channel: string) {
    const id = generateSiweNonce().slice(0, 32)
    if (subscribeList.value[channel]) {
      sendWs(JSON.stringify({
        type: 'unsubscribe',
        ...subscribeList.value[channel].params,
      }))
    }
    subscribeList.value[channel] = {
      id,
      params: {
        channel,
        id,
        symbol,
      },
    }
    sendWs(JSON.stringify({
      type: 'subscribe',
      channel,
      id,
      symbol,
    }))
  }
  function subscribeSymbolKLine(symbol: string, unit: string) {
    const id = generateSiweNonce().slice(0, 32)
    if (subscribeList.value.kline) {
      sendWs(JSON.stringify({
        type: 'unsubscribe',
        ...subscribeList.value.kline.params,
      }))
    }
    subscribeList.value.kline = {
      id,
      params: {
        channel: 'kline',
        id,
        symbol,
        interval: unit,
      },
    }
    sendWs(JSON.stringify({
      type: 'subscribe',
      channel: 'kline',
      id,
      symbol,
      interval: unit,
    }))
  }

  function subscribeBalance() {
    closeAssetBalanceWs()
    subscribeAssetBalanceWs()
  }

  watch(currentSymbol, (symbol) => {
    initList()
    subscribeSymbolChannel(symbol, 'orderbook')
    subscribeSymbolChannel(symbol, 'trade')
  })

  return {
    assetBalance,
    subscribeBalance,
    tradeList,
    orderbookList,
    kLineList,
    kLineUnit,
    symbolPrice,
    subscribeSymbolKLine,
    patchKLine,
    isNeedRefresh,
    isBalanceChange,
    closeAssetBalanceWs,
  }
})

export interface OrderbookItem {
  price: string
  volume: string
  orderCount: string
}

export interface Orderbook {
  asks: OrderbookItem[]
  bids: OrderbookItem[]
}

export interface TradeItem {
  id: string
  price: string
  quantity: string
  buyerOrderID: string
  sellerOrderID: string
  tradeTime: string
  isBuyerMaker: boolean
}

export interface KLineItem {
  openTime: string
  openPrice: string
  highPrice: string
  lowPrice: string
  closePrice: string
  volume: string
  trades: string
  closeTime: string
  quoteVolume: string
  takerBuyBaseVolume: string
  takerBuyQuoteVolume: string
}

export interface SymbolPricePatch {
  closePrice: string
  closeTime: string
  highPrice: string
  lowPrice: string
  openPrice: string
  openTime: string
  quoteVolume: string
  takerBuyBaseVolume: string
  takerBuyQuoteVolume: string
  trades: string
  volume: string
}
export type SymbolPrice = Record<string, SymbolPricePatch>

export interface BalanceItem {
  assetName: string
  available: string
  inUse: string
}

export interface DepositItem {
  address: string
  amount: string
  assetName: string
}

export interface SubscribeInfo {
  id: string
  params: Record<string, string>
}

export interface OrderItem {
  side: string
  price: string
  origQty: string
  executedQty: string
  symbol: string
  status: string
}
