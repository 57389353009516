const statsApi = {
  verifyPassword: (params: { code: string }) => http.get('/gateway/public/invitationCode/verify', { params }),
  // checkCode: (params: CheckCode) => http.get<A<CheckCodeResponse>>(
  //   '/stats/invitationCode/verify',
  //   { params },
  // ),
  verifyAddress: (params: { address: string }) => http.get('/gateway/public/invitationCode/verifyAddress', { params }),
  bindCode: (data: CheckCode) => http.post('/gateway/user/invitationCode/bind', data),

  getMintedList: () => http.get<MintedRes>('/gateway/user/token/mint'),
  getAllMintList: (params: { is_minable?: boolean }) => http.get<MintListRes>('/gateway/public/token', { params }),
  addMint: (data: AddMint) => http.post('/gateway/user/token/mint', data),

  getProverStatus: (params: ProverRequest) => http.get<ProverResponse>('/gateway/public/prover/status', { params }),
  getPreference: () => http.get<A<UserPreference>>('/gateway/user/preference/setting'),
  updatePreference: (data: UserPreference) => http.post('/gateway/user/preference/setting', data),

  feedback: (data: FormData) => http.post('/gateway/public/feedback/general', data),
  reportBug: (data: FormData) => http.post('/gateway/public/feedback/bug', data),
  getCopywriting: (params: { key: string }) => http.get('/gateway/public/feedback/copywriting', { params }),
  submitQuestionnaire: (data: any) => http.post('/gateway/public/feedback/questionnaire', data),

  getAirdropInteractionStatus: () => http.get('/stats/user/interaction/status'),
  getAirdropInfo: () => http.get('/stats/user/airdrop/overview'),
  getAirdropReferralMember: () => http.get('/stats/user/referral/member'),
  getAirdropLoyaltyHistory: () => http.get('/stats/user/loyalty/history'),
  getCodeAllocation: () => http.get('/gateway/public/config/code/allocation'),

  getAvgCost: () => http.get<A<{ costs: AvgCostItem[] }>>('/gateway/user/token/averageCost'),
  getStartBalance: () => http.get<A<{ balances: StartBalanceItem[] }>>('/gateway/user/balance/startOfDay'),
  getSpotHistory: (params: { periods: number }) => http.get<A<{ history: SpotHistory[] }>>('/gateway/user/balance/history', { params }),

  getVoyageSummary: () => http.get<A<VoyagePoolSummary>>('/gateway/user/voyage/summary'),
  getVoyageLeaderboard: () => http.get<A<{ data: VoyageLeaderboardItem[] }>>('/gateway/public/voyage/leaderboard'),
  getReferralInfo: () => http.get<A<ReferralRes>>('/gateway/user/voyage/referral'),
  getTwitterUrl: () => http.get<A<{ authorizationUrl: string }>>('/gateway/user/voyage/twitter/authorization'),
  postTwitterCode: (data: { state: string; code: string }) => http.post('/gateway/user/voyage/twitter/action', data),
  unlockInviteCode: () => http.post('/gateway/user/invitationCode/generate'),

  getStatsUserInfo: () => http.get<A<StatsUserInfo>>('/gateway/user/info'),
}

export default statsApi

export interface StatsUserInfo {
  ownInvitationCode: string
  usedInvitationCode: string
  userAddress: string
  userName: string
}

export interface ReferralPointsAllocationConfig {
  points: string
  threshold: string
  rank: string
}

export interface ReferralTeamMember {
  joinedDate: string
  lpPointReturn: string
  referralPoint: string
  userName: string
}

export interface ReferralRes {
  hasSufficientDeposit: boolean
  isFollowingOfficial: boolean
  referralPointsAllocationConfig: ReferralPointsAllocationConfig[]
  teamMemberCount: string
  teamMembers: ReferralTeamMember[]
}

export interface VoyageLeaderboardItem {
  lpPoint24h: string
  lpPointRank: string
  lpPointTotal: string
  rankBoost: string
  userName: string
}

export interface VoyagePoolSummary {
  error: boolean
  lpPoint24h: string
  lpPointRank: string
  lpPointTotal: string
  referralPointTotal: string
  msg: string
  poolsPoint: PoolsPoint[]
  rankBoost: string
  updatedTime: null
  userName: string
}

export interface PoolsPoint {
  point?: string
  poolId?: string
}

export interface SpotHistory {
  accountHoldValue: string
  avgTotalCost: string
  dt: string
  profit: string
  profitRate: string
}

export interface StartBalanceItem {
  tokenAddress: string
  balance: string
  price: string
}
export interface AvgCostItem {
  tokenAddress: string
  avgCostPrice: string
}

export interface UserPreference {
  userAddress: string
  orderConfirmation: boolean
  cancelAllConfirmation: boolean
  tradeNotification: boolean
  clickDepthInputAmount: boolean
  priceTime24h: string
}

export interface ProverBatchItem {
  id: string
  type: string
  status: string
  transactionHash: string
  createTimestamp: string
  afterMerkleRoot: string
  beforeMerkleRoot: string
  updateTimestamp: string
  eventIdRange: string[]
}

export interface ProverResponse {
  count: number
  maxCommittedId: number
  maxPendingId: number
  maxProofGeneratedId: number
  proofGeneratedCount: number
  batches: ProverBatchItem[]
  eventIdRange: string[]
}

interface ProverRequest {
  page: number
  page_size: number
}

interface AddMint {
  tokenAddress: string
  transactionHash: string
}

interface CheckCodeResponse {
  isValid: boolean
  isNew: boolean
  address: string
}

interface CheckCode {
  address: string
  code: string
}

export interface MintItem {
  address: string
  amount: string
  decimal: string
  interval: string
  name: string
  iconUrl: string
  fullName: string
  isMinable: boolean
}

export interface MintListRes {
  tokens: MintItem[]
}

export interface MintedItem {
  tokenAddress: string
  timestamp: string
}

export interface MintedRes {
  tokens: MintedItem[]
  isNewUser: boolean
}
